<script lang="ts" setup>
import type { EditorialSocialStrip } from '@integration-layer/ampliance/schemas/editorial-social-strip-schema.localized'
import type { EditorialSocialStripProps } from '@design-system/components/Editorial/EditorialSocialStrip.props'

const props = defineProps<{
  deliveryId: string
  locale: string
  vse?: string
  content?: EditorialSocialStrip
}>()

const data = await useComponentResponse<EditorialSocialStrip>(props)
</script>

<template>
  <EditorialSocialStrip
    v-if="data"
    v-bind="data as unknown as EditorialSocialStripProps"
  />
</template>
